import axios from 'axios';


const instance = axios.create({
  // baseURL: "https://192.168.100.5:3333/"
  // baseURL: "http://localhost:3333/"
  baseURL: "https://api2.cia.pizza/"
  // baseURL: "https://apitst.ddns.net/" 
  // baseURL: "https://ciapizza.ddns.net:8030/"
});



instance.interceptors.request.use(config => {
  return config;
});

instance.interceptors.response.use(res => {
  return res;
}, (error) => {
  console.log(error);
  if (error && error.response && error.response.status == 401 && location.href.indexOf('/login') < 0) {
    window.location.href = "./logout"
  }
});

export default instance;